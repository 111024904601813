@tailwind base;
@tailwind components;
@tailwind utilities;


body{
font-family:  Helvetica, Arial, sans-serif;
letter-spacing: 0.16rem;
background: rgb(181,217,186);
background: linear-gradient(145deg, rgba(181,217,186,0.9766500350140056) 11%, rgba(107,148,205,0.9514399509803921) 81%);
--black: #111111;
--white: #fdfdfd;
--green: #0e7e4f;
--blue: #0055ff;
--purple: #8855ff;
--red: #ff0055;
--orange: #ee4444;
--yellow: #ffcc00;
--mustard: #ffaa00;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  box-shadow: inset 0 2px 4px rgb(186 222 214 / 10%);
  background: rgb(103 174 156 / 52%);
  background: linear-gradient(145deg, rgba(52, 165, 162, 0.977) 11%, rgb(6 4 75 / 95%) 81%);  transform-origin: 0%;
}
.portfolio {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.portfolio-title {
  text-align: center;
  margin-bottom: 30px;
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.project-card {
  display: flex;
  color: whitesmoke;
  align-items: center;
  justify-content: center;
  border: 1px solid #15ecad;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: inset 0 2px 4px rgb(186 222 214 / 10%);
  background: rgb(103 174 156 / 52%);
  background: linear-gradient(145deg, rgba(52, 165, 162, 0.977) 11%, rgb(6 4 75 / 95%) 81%);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-details {
  padding: 20px;
}

.project-title {
  margin-top: 0;
}

.project-description {
  margin-bottom: 10px;
}

.project-link {
  display: block;
  text-align: center;
  background-color: #007bff;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  text-decoration: none;
}

.project-link:hover {
  background-color: #0056b3;
}
.skill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 20px;
}
.skill-card {
  display: flex;
  color: whitesmoke;
  align-items: center;
  justify-content: center;
  border: 1px solid #15ecad;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: inset 0 2px 4px rgb(186 222 214 / 10%);
  background: rgb(103 174 156 / 52%);
  background: linear-gradient(145deg, rgba(52, 165, 162, 0.977) 11%, rgb(6 4 75 / 95%) 81%);
}
.skill-details{
  padding: 20px;
}


.contact-form {
  color: whitesmoke;
  padding: 20px;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  box-shadow: inset 0 2px 4px rgb(186 222 214 / 10%);
  background: rgb(103 174 156 / 52%);
  background: linear-gradient(145deg, rgba(52, 165, 162, 0.977) 11%, rgb(6 4 75 / 95%) 81%);
}

.contact-form__button{
  color: white;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: inset 0 2px 4px rgb(186 222 214 / 10%);
  background: rgb(103 174 156 / 52%);
  background: linear-gradient(145deg, rgba(52, 165, 162, 0.977) 11%, rgb(6 4 75 / 95%) 81%);

}

.box {
  width: 150px;
  height: 75px;
  box-shadow: inset 0 2px 4px rgb(186 222 214 / 10%);
  background: rgb(103 174 156 / 52%);
  background: linear-gradient(145deg, rgba(52, 165, 162, 0.977) 11%, rgb(6 4 75 / 95%) 81%);
  color: #fff;
  padding: 20px;
  margin: 10px 40px 10px 40px;
  position: relative;
  float: left;
}

.box.arrow-top {
  margin-top: 40px;
}

.box.arrow-bottom:after {
  content: " ";
  position: absolute;
  right: 30px;
  bottom: -15px;
  border-top: 15px solid rgb(8, 34, 16);
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
  position: absolute;
}


